import React, { Component } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  shape,
  string,
  oneOf,
  object,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  capitalize,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import {
  createResourceLocatorString,
  findRouteByRouteName,
} from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  Modal,
  Button,
  ReviewRating,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  sendOffer,
  sendMessage,
  sendMessageOffer,
} from './ListingPage.duck';
import SectionAvatar from './SectionAvatar';
import ActionBarMaybe from './ActionBarMaybe';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import SectionSlider from './SectionSlider';

import { Embed, CommentCount } from 'hyvor-talk-react';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MODAL_MOBILE_BREAKPOINT = 768;
const { Money, UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      bookmarks:
        props.currentUser &&
        props.currentUser.attributes &&
        props.currentUser.attributes.profile &&
        props.currentUser.attributes.profile.publicData &&
        props.currentUser.attributes.profile.publicData.bookmarks
          ? props.currentUser.attributes.profile.publicData.bookmarks
          : [],
      offerModalOpen: false,
      offerPrice: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitOffer = this.onSubmitOffer.bind(this);
    this.handleWishlist = this.handleWishlist.bind(this);
    this.toggleEnquiryModal = this.toggleEnquiryModal.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
    this.handleOfferPrice = this.handleOfferPrice.bind(this);
  }

  handleOfferPrice(e) {
    const offerPrice = e.target.value;
    this.setState({ offerPrice: offerPrice });
  }

  toggleEnquiryModal() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
    } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: !this.state.enquiryModalOpen });
    }
  }

  onSubmitModal() {
    const { history, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {})
      );
    } else {
      this.setState({
        offerModalOpen: !this.state.offerModalOpen,
        offerPrice: null,
      });
    }
  }

  handleWishlist(id, e) {
    const {
      onUpdateProfile,
      currentUser,
      isAuthenticated,
      history,
    } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (!isAuthenticated && e && id) {
      const routes = routeConfiguration();
      history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
    }

    const bookmarks =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.bookmarks;

    const index = bookmarks && bookmarks.findIndex(b => b == id);

    if (id) {
      if (index > -1) {
        bookmarks && bookmarks.splice(index, 1);
        const removedBookmarks = Array.from(new Set(bookmarks));
        const profile = {
          publicData: {
            bookmarks: removedBookmarks,
          },
        };
        onUpdateProfile(profile);
      } else {
        this.state.bookmarks.push(id);
        const addedBookmarks = Array.from(new Set(this.state.bookmarks));
        const profile = {
          publicData: {
            bookmarks: addedBookmarks,
          },
        };
        onUpdateProfile(profile);
      }
    }
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const {
      bookingDates,
      quantity: quantityRaw,
      deliveryMethod,
      ...otherOrderData
    } = values;
    const bookingDatesMaybe = bookingDates
      ? {
          bookingDates: {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          },
        }
      : {};

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        quantity: Number.parseInt(quantityRaw, 10),
        deliveryMethod,
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
          tab: 'stripe',
        },
        {}
      )
    );
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
    } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

 async onSubmitEnquiry(values) {
    const {
      history,
      params,
      onSendEnquiry,
      currentUser,
      getListing,
      onSendMessage,
    } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    const listing = getListing(listingId);

    const phoneNumber =
      listing &&
      listing.author &&
      listing.author.attributes &&
      listing.author.attributes.profile &&
      listing.author.attributes.profile.publicData &&
      listing.author.attributes.profile.publicData.phoneNumber;

    const txData =
      listing &&
      listing.attributes &&
      listing.attributes.publicData &&
      listing.attributes.publicData.txData
        ? listing.attributes.publicData.txData
        : [];
    const index =
      txData &&
      txData.findIndex(item => item.authorId == currentUser?.id?.uuid);
    const updateParams = {
      authorId: currentUser?.id?.uuid,
      txData,
      index,
    };

    if (index > -1) {
      const currentTx = txData && txData[index] && txData[index].txId;

      const viewUrl = `${config &&
        config.canonicalRootURL}/sale/${currentTx}/details`;
      const newMessage =
        `Hi, you've got a new message from ${currentUser?.attributes?.profile?.displayName} :` +
        message;

      onSendMessage(
        new UUID(currentTx),
        message.trim(),
        newMessage,
        phoneNumber
      )
        .then(txId => {
          this.setState({ enquiryModalOpen: false });

          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString(
              'OrderDetailsPage',
              routes,
              { id: currentTx },
              {}
            )
          );
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    } else {
      const newMessage =
        `Hi, you've got a new message from ${currentUser?.attributes?.profile?.displayName} :` +
        message;

      const transactionId = await onSendEnquiry(
        listingId,
        message.trim(),
        updateParams,
        newMessage,
        phoneNumber
      );

      if (transactionId) {
        history.push(
          createResourceLocatorString(
            'OrderDetailsPage',
            routes,
            { id: transactionId.uuid },
            {}
          )
        );
      }
    }
  }

  onSubmitOffer() {
    const {
      history,
      params,
      onSendOffer,
      currentUser,
      getListing,
      onSendMessageOffer,
    } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const offerPrice = this.state.offerPrice ? new Money(this.state.offerPrice, 'USD') : "";

    const listing = getListing(listingId);

    const phoneNumber =
      listing &&
      listing.author &&
      listing.author.attributes &&
      listing.author.attributes.profile &&
      listing.author.attributes.profile.publicData &&
      listing.author.attributes.profile.publicData.phoneNumber;

    const txData =
      listing &&
      listing.attributes &&
      listing.attributes.publicData &&
      listing.attributes.publicData.txData
        ? listing.attributes.publicData.txData
        : [];
    const index =
      txData &&
      txData.findIndex(item => item.authorId == currentUser?.id?.uuid);
    const updateParams = {
      authorId: currentUser?.id?.uuid,
      txData,
      index,
    };

    if (index > -1) {
      const currentTx = txData && txData[index] && txData[index].txId;

      const viewUrl = `${config &&
        config.canonicalRootURL}/sale/${currentTx}/details`;
      const newMessage =
        `Hi, ${
          currentUser?.attributes?.profile?.displayName
        } would like to make an offer for $${offerPrice &&
          offerPrice.amount} for ${listing.attributes.title}`;

      onSendMessageOffer(
        new UUID(currentTx),
        offerPrice,
        newMessage,
        phoneNumber
      )
        .then(txId => {
          this.setState({ offerModalOpen: false });

          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString(
              'OrderDetailsPage',
              routes,
              { id: currentTx },
              {}
            )
          );
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    } else {
      const newMessage = `Hi, ${
        currentUser?.attributes?.profile?.displayName
      } would like to make an offer of $${offerPrice &&
        offerPrice.amount} for ${listing.attributes.title}`;

      onSendOffer(listingId, offerPrice, updateParams, newMessage, phoneNumber)
        .then(txId => {
          this.setState({ offerModalOpen: false });

          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString(
              'OrderDetailsPage',
              routes,
              { id: txId.uuid },
              {}
            )
          );
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    }
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      sendOfferInProgress,
      offerSent,
      sendOfferError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant =
      rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug =
      rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage =
      pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return (
        <NamedRedirect
          name="ListingPage"
          params={params}
          search={location.search}
        />
      );
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage
        id="ListingPage.bookingTitle"
        values={{ title: richTitle }}
      />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable &&
      currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser =
      authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      const isCurrentlyClosed =
        currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(
      currentListing,
      `${config.listing.variantPrefix}-2x`
    ).map(img => img.url);
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand
      ? { brand: { '@type': 'Brand', name: brand } }
      : {};
    const schemaPriceNumber = intl.formatNumber(convertMoneyToNumber(price), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter(
      'amenities',
      customConfig.filters
    );
    const categoryOptions = findOptionsForSelectFilter(
      'category',
      customConfig.filters
    );
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    const bookmarks =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.bookmarks;
    const canonicalRootURL = config.canonicalRootURL;

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout =
      isWindowDefined && window.innerWidth < MODAL_MOBILE_BREAKPOINT;
    const isOfferPriceMoreThan15 = this.state.offerPrice && this.state.offerPrice >= 15;
    console.log(isOfferPriceMoreThan15)
    const offerModal = (
      <Modal
        id="EditListingWizard.payoutModal"
        isOpen={this.state.offerModalOpen || sendOfferError}
        onClose={this.onSubmitModal}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <h2>Make an offer to {authorDisplayName}.</h2>
        <div className={css.priceFld}>
          <input
            id="offerPrice"
            name="offerPrice"
            placeholder="Please enter your offer price."
            type="number"
            onChange={this.handleOfferPrice}
            pattern="\d*"
            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
          />
           {typeof isOfferPriceMoreThan15 !== 'undefined' && isOfferPriceMoreThan15 == false ? <p className={css.priceError}>Minimum price should be $15</p> : null}
        </div>
        <Button
          onClick={this.onSubmitOffer}
          inProgress={sendOfferInProgress}
          ready={offerSent}
          disabled={!isOfferPriceMoreThan15}
        >
          Send Offer
        </Button>
        {sendOfferError && <p>Error while sending offer. Please try again.</p>}
      </Modal>
    );

    const totalReviewCount =
      reviews && reviews.length && reviews.map(item => item.attributes.rating);
    const countReduced =
      totalReviewCount &&
      totalReviewCount > 0 &&
      totalReviewCount.reduce((partialSum, a) => partialSum + a, 0);
    const reviewCount = countReduced && countReduced / reviews?.length;
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainColumnForProductLayout}>
                {currentListing.id ? (
                  <ActionBarMaybe
                    className={css.actionBarForProductLayout}
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                  />
                ) : null}
                <SectionGallery listing={currentListing} />
                <div className={css.productMobileHeading}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    authorLink={authorLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    isMobileLayout={isMobileLayout}
                    bookmarks={bookmarks}
                    handleWishlist={this.handleWishlist}
                    id={currentListing?.id?.uuid}
                    reviewCount={reviewCount}
                  />
                </div>
               {!isMobileLayout &&
                <SectionDescriptionMaybe
                  description={description}
                  listingTitle={richTitle}
                />}

                {/* <h2>Discussions</h2>
                <Embed
                  websiteId={6865}
                  id={`${canonicalRootURL}/l/${createSlug(
                    currentListing?.attributes?.title
                  )}/${currentListing?.id?.uuid}`}
                /> */}

                {/* <SectionDetailsMaybe publicData={publicData} customConfig={customConfig} /> */}
                {/* <SectionFeaturesMaybe
                  extendedDataKey="amenities"
                  options={amenityOptions}
                  publicData={publicData}
                /> */}
                {/* <SectionRulesMaybe publicData={publicData} /> */}
                {/* <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                /> */}
                {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
                {/* <SectionAuthorMaybe
                  title={title}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={this.onContactUser}
                  isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                  onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                  sendEnquiryError={sendEnquiryError}
                  sendEnquiryInProgress={sendEnquiryInProgress}
                  onSubmitEnquiry={this.onSubmitEnquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                /> */}
                {/* <SectionSlider/> */}

                {/* <div className={css.reviews}>
                  <h2>Review and Ratings</h2>
                  <ReviewRating
                    rating={reviewCount ? reviewCount : 0}
                    className={css.desktopReviewRating}
                    reviewStarClassName={css.reviewRatingStar}
                  />
                </div> */}
                <SectionAuthorMaybe
                  title={title}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={this.onContactUser}
                  isEnquiryModalOpen={
                    isAuthenticated && this.state.enquiryModalOpen
                  }
                  onCloseEnquiryModal={() =>
                    this.setState({ enquiryModalOpen: false })
                  }
                  sendEnquiryError={sendEnquiryError}
                  sendEnquiryInProgress={sendEnquiryInProgress}
                  onSubmitEnquiry={this.onSubmitEnquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onSubmitOffer={this.onSubmitOffer}
                />
              </div>
              <div className={css.orderColumnForProductLayout}>
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleOrderSubmit}
                  title={bookingTitle}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={this.onContactUser}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  bookmarks={bookmarks}
                  handleWishlist={this.handleWishlist}
                  geolocation={geolocation}
                  publicData={publicData}
                  isMobileLayout={isMobileLayout}
                  toggleEnquiryModal={this.toggleEnquiryModal}
                  onSubmitModal={this.onSubmitModal}
                  reviewCount={reviewCount}
                  reviews={reviews}
                  fetchReviewsError={fetchReviewsError}
                  description={description}
                  richTitle={richTitle}
                  isListingPage={true}
                />
              </div>
              {offerModal}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    sendOfferInProgress,
    offerSent,
    sendOfferError,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendOfferInProgress,
    offerSent,
    sendOfferError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, updateParams, newMessage, phoneNumber) =>
    dispatch(
      sendEnquiry(listingId, message, updateParams, newMessage, phoneNumber)
    ),
  onSendMessage: (txId, message, newMessage, phoneNumber) =>
    dispatch(sendMessage(txId, message, newMessage, phoneNumber)),
  onSendOffer: (listingId, offerPrice, updateParams, newMessage, phoneNumber) =>
    dispatch(
      sendOffer(listingId, offerPrice, updateParams, newMessage, phoneNumber)
    ),
  onSendMessageOffer: (listingId, offerPrice, newMessage, phoneNumber) =>
    dispatch(sendMessageOffer(listingId, offerPrice, newMessage, phoneNumber)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
